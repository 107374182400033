import React from "react"
import convertHtmlToReact from "@hedgedoc/html-to-react";
import styled from "styled-components"
import Layout from "../components/layout"
import Slide from "../components/slide"
import { graphql } from 'gatsby'
import Seo from "../components/seo";
import ContactForm from "../components/contact"

const Project = ({ data }) => {
  const { title, body, relationships: {field_project_images: picture}} = data.nodeProject;
  const body_elements = body && body.value ? convertHtmlToReact(body.value) : null;

  let pictures = picture ? picture.map((item) => {
    return {
      alt: item.field_media_image.alt,
      title: item.field_media_image.title,
      src: item.relationships.field_media_image.publicUrl
    };
  }) : [];

  return (
    <Layout>
      <Seo
        title={title}
        description={body && body.summary ? body.summary : `${title} by Jake on the Lakes`}
        keywords={["Jake On The Lakes", "Pile Driving Services", "New Dock Construction", "Barging Services", "Coeur d’Alene Lake", "Hauser Lake", "Newman Lake", "Priest Lake", "Pend Oreille Lake", "Cocolalla Lake", "Spokane River", "Expertise In The Northwest"]}
      />
      <Container>
        <Title style={{letterSpacing: "4px"}}>{ title }</Title>
          <DotContainer>
              <Dot/>
              <Dot/>
              <Dot/>
          </DotContainer>

        <Slide items={pictures}/>

        {body_elements}
      </Container>
    <ContactForm data={data} />
  </Layout>
  );
};

export default Project

export const query = graphql`
  query ProjectQuery($slug: String!) {
    nodeProject(fields: { slug: { eq: $slug } }) {
      title
      body {
        value
        summary
      }
      relationships {
        field_project_images {
          field_media_image {
            alt
            title
          }
          relationships {
            field_media_image {
              publicUrl
            }
          }
        }
      }
      changed
      created
    }
    webformWebform(drupal_internal__id: { eq: "9gk5ucvtcrek9dgqtqq28a8fkvvhkdb2" }) {
      drupal_internal__id
      elements {
          name
          type
          attributes {
            name
            value
          }
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  font-family: 'Raleway', sans-serif;
  color: #707070;
`;

const Title = styled.h1`
  font-size: 45px;
  text-align: center;
  margin-top: 150px;
  letter-spacing: 16.5px;
  line-height: normal;
  width: 100%;
  font-weight: normal;
`;

const Dot = styled.div`
  height: 8px;
  width: 8px;
  background-color: #fff;
  border: #707070 solid thin;
  border-radius: 50%;
  margin: 20px;
`;

const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px;
  width: 100%;
  margin-bottom: -15px;
`;

import React from 'react';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {TeamCard} from "../styles/slideStyles"

const S = ({items}) => {
    let data = items;
    return (
        <CarouselProvider
        visibleSlides={1}
        totalSlides={data.length}
        naturalSlideWidth={400}
        naturalSlideHeight={500}
        style={{
            display: "flex",
            width: "100%",
            height: "80vh",
            justifyContent: "space-evenly",
            alignItems: "center",
        }}
    >
        <ButtonBack style={{
            fontSize: "38px",
            fontWeight: "bold",
            border: "none",
            backgroundColor: "white",
            outline: "none",
            focus: "none",
        }}>&lt;</ButtonBack>
        <div style={{
            display: "flex",
            width: "80vw",
            height: "75vh",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Slider style={{
            width: "100%",
            height: "100%",
        }}>
            {data ? data.map((item, i) => {
                    return (
                        <Slide tag="a" index={i} >
                            <TeamCard {...item} />
                        </Slide>
                    );
                }) : null}  
            </Slider>
        </div>
        <ButtonNext style={{
            fontSize: "38px",
            fontWeight: "bold",
            border: "none",
            backgroundColor: "white",
            focus: "none",
            outline: "none",
        }}>&gt;</ButtonNext>
    </CarouselProvider>  
    );
};

export default S;
import styled from "styled-components";


const TeamCard = styled.img`
    height: 68vh;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 3.5vh;
    font-family: 'Raleway', sans-serif;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    object-fit: contain;
`;

const CardTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
`;

const CardImg = styled.img`
    height: 50%;
    width: 100%;
    border: solid white;
    border-width: thin;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
`;

const CardPara = styled.div`
    width: 60%;
`;

const LeftCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 40vh;
    margin: 20px;
    align-items: center;
`;

export {
    TeamCard,
    CardTitle,
    CardImg,
    Container,
    CardPara,
    LeftCard
}